import { useEffect, useRef } from "react";

import { useRouter } from "next/router";

import { t } from "@lingui/macro";
import { Modal } from "antd";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";

import { appConfigAtom } from "atoms/configurations";

const PASSWORD_CHANGE_URL = "/user/authentication";

const useHandleExpiredPassword = () => {
  const configuration = useAtomValue(appConfigAtom);
  const { route, push } = useRouter();

  const didPrompt = useRef(false);

  useEffect(() => {
    if (configuration && route !== PASSWORD_CHANGE_URL) {
      const now = dayjs();
      const expiresAt = configuration?.user?.password_expires_at;

      if (expiresAt && now.isAfter(dayjs(expiresAt)) && didPrompt.current === false) {
        didPrompt.current = true;
        Modal.info({
          type: "info",
          title: t`Your password has expired`,
          content: t`Please update your password to keep your account secure.`,
          onOk: () => push(`${PASSWORD_CHANGE_URL}`),
          okText: t`Change password`,
          cancelText: t`Close`,
          closable: true,
        });
      }
    }
  }, [configuration]);
};

export default useHandleExpiredPassword;
