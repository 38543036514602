import retryRequest from "utils/retry-request";
import { t } from "@lingui/macro";
import { Button, FormInstance, message, notification, Space } from "antd";
import axios, { Axios } from "axios";
import dayjs from "dayjs";
import { chunk, isEmpty } from "lodash";
import lodashGet from "lodash/get";
import parseLinkHeader from "parse-link-header";
import { BareFetcher } from "swr";
import { SWRInfiniteKeyLoader } from "swr/infinite";
import { v4 as uuidv4 } from "uuid";

import { onClearFieldsError, onHandleError } from "utils/error-utils";
import { throttleRequest } from "utils/request-throttling-utils";
import { httpStatusCodes } from "utils/status-codes-utils";

export const API_VERSION = "1.39.1";
export const TIMEOUT = 35; // value in seconds
export const REQUEST_TIMEOUT_IN_MILLISECONDS = 1000 * TIMEOUT;
export const DEFAULT_PAGE_SIZE = 100;
export const DEFAULT_PAYLOAD_LENGTH = 100;

export interface IHttpClientConfig {
  data?: object;
  successMsg?: string;
  form?: FormInstance;
  successHandler?: (data: any, props?: any) => void;
  ignoreError?: boolean;
  errorHandler?: (err: any) => void;
  config?: any;
  bare?: boolean;
}

export interface IHttpClient {
  client: Axios;
  readonly basePath: string | undefined;
  readonly headers: { [key: string]: string };
  readonly apiVersion: string | undefined;

  readonly get: (path: string, config?: IHttpClientConfig) => any;
  readonly fetchAll: (path: string, config?: IHttpClientConfig) => any;
  readonly options: (path: string, config?: IHttpClientConfig) => any;
  readonly patch: (path: string, config?: IHttpClientConfig) => any;
  readonly post: (path: string, config?: IHttpClientConfig) => any;
  readonly chunkedPost: (path: string, config?: IHttpClientConfig) => any;
  readonly put: (path: string, config?: IHttpClientConfig) => any;
  readonly remove: (path: string, config?: IHttpClientConfig) => any;
  readonly swrFetcher: BareFetcher<any>;
  readonly swrInfiniteFetcher: BareFetcher<any>;
  readonly getKey: (url: string | undefined, preset?: "next" | "prev") => SWRInfiniteKeyLoader;
  readonly bareFetcher: BareFetcher<any>;
  readonly multipleFetcher: (urls: string[]) => Promise<any[]>;
}

const createHTTPClient = (
  baseURL: string,
  token: string | undefined,
  timeZone: string | null = null,
  _language?: string,
  tokenPrefix: string = "Token"
): IHttpClient => {
  const language = _language || "en";
  const defaultHeaders: any = {
    Authorization: token ? `${tokenPrefix} ${token}` : undefined,
    Accept: `application/json; version=${API_VERSION}`,
    "Accept-Language": language,
  };
  const listOfThrottledRequest: { [key: string]: dayjs.Dayjs } = {};

  if (timeZone) {
    defaultHeaders["Accept-Timezone"] = timeZone;
  }

  const client = axios.create({
    baseURL: baseURL,
    headers: defaultHeaders,
    timeout: REQUEST_TIMEOUT_IN_MILLISECONDS,
  });

  throttleRequest(client, listOfThrottledRequest);
  retryRequest(client);

  const get = async (path: string, config?: IHttpClientConfig) => {
    try {
      client.defaults.headers.common["X-Request-Id"] = uuidv4();
      const response = await (config?.bare ? axios : client).get(path, config?.config);
      if (httpStatusCodes.OK === response.status || httpStatusCodes.NO_CONTENT === response.status) {
        config?.successHandler && config.successHandler(response.data);
        return response.data;
      }
      onHandleError(response);
      return;
    } catch (e) {
      // @ts-ignore
      onHandleError(e?.response || e.message);
      return;
    }
  };

  const options = async (path: string, config?: IHttpClientConfig) => {
    try {
      client.defaults.headers.common["X-Request-Id"] = uuidv4();
      const response = await (config?.bare ? axios : client).options(path);
      if (httpStatusCodes.OK === response.status) {
        config?.successHandler && config?.successHandler(response.data);
        return response.data;
      }

      if (!config?.ignoreError) {
        onHandleError(response);
        config?.errorHandler && config?.errorHandler(response.data);
      }

      return;
    } catch (e) {
      if (!config?.ignoreError) {
        // @ts-ignore  TODO: Type error: Object is of type 'unknown'.
        onHandleError(e?.response || e.message);
        config?.errorHandler && config?.errorHandler(e);
      }
      return;
    }
  };

  const patch = async (path: string, config?: IHttpClientConfig) => {
    try {
      client.defaults.headers.common["X-Request-Id"] = uuidv4();
      const response = await (config?.bare ? axios : client).patch(path, config?.data);
      if (httpStatusCodes.OK === response.status) {
        config?.successMsg && message.success(config?.successMsg);
        config?.successHandler && config?.successHandler(response.data);
        if (config?.form) {
          onClearFieldsError(config.form);
        }

        return response.data;
      }

      onHandleError(response, config?.form);
      config?.errorHandler && config?.errorHandler(response.data);
      return response;
    } catch (e) {
      config?.errorHandler && config?.errorHandler(e);
      // @ts-ignore
      onHandleError(e?.response || e.message, config?.form);
      return;
    }
  };

  const post = async (path: string, config?: IHttpClientConfig) => {
    try {
      client.defaults.headers.common["X-Request-Id"] = uuidv4();
      const response = await (config?.bare ? axios : client).post(path, config?.data || {}, config?.config);
      if ([httpStatusCodes.OK, httpStatusCodes.CREATED].includes(response.status)) {
        config?.successMsg && message.success(config.successMsg);
        config?.successHandler && config.successHandler(response.data);

        return response.data;
      }

      if (config?.errorHandler) {
        config.errorHandler(response);
      } else {
        onHandleError(response, config?.form);
      }

      return response;
    } catch (e) {
      if (config?.errorHandler) {
        config.errorHandler(e);
        return;
      }
      // @ts-ignore
      onHandleError(e?.response || e.message, config?.form);
    }
  };

  const chunkedPost = async (path: string, config?: IHttpClientConfig) => {
    const chunkedPayload = chunk(config?.data as Array<Object>, DEFAULT_PAYLOAD_LENGTH);
    const retriable: any[] = [];
    const success = () => {
      if (config?.successHandler) {
        config?.successHandler(null);
      }
    };

    await Promise.allSettled(
      chunkedPayload?.map(async (payload) => {
        await post(path, {
          data: payload,
          errorHandler: () => {
            retriable.push(...payload);
          },
        });
      })
    );

    if (!isEmpty(retriable)) {
      notification.error({
        message: t`An error occurred`,
        description: t`Some request failed`,
        duration: 0,
        btn: (
          <Space>
            <Button
              type="default"
              size="small"
              onClick={() => {
                success();
                notification.destroy();
              }}
            >
              {t`Close`}
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                message.loading(`Retrying failed request`);
                chunkedPost(path, { ...config, data: retriable });
                notification.destroy();
              }}
            >
              {t`Retry`}
            </Button>
          </Space>
        ),
      });
    } else {
      success();
    }
  };

  const put = async (path: string, config?: IHttpClientConfig) => {
    try {
      client.defaults.headers.common["X-Request-Id"] = uuidv4();

      const response = await (config?.bare ? axios : client).put(path, config?.data);
      if (httpStatusCodes.OK === response.status) {
        config?.successMsg && message.success(config.successMsg);
        config?.successHandler && config.successHandler(response.data);

        return response.data;
      }

      onHandleError(response, config?.form);
      return response;
    } catch (e) {
      if (config?.errorHandler) {
        config.errorHandler(e);
      }
      // @ts-ignore
      onHandleError(e?.response || e.message, config?.form);
    }
  };

  const remove = async (path: string, config?: IHttpClientConfig) => {
    try {
      client.defaults.headers.common["X-Request-Id"] = uuidv4();
      const response = await (config?.bare ? axios : client).delete(path);
      if ([httpStatusCodes.NO_CONTENT, httpStatusCodes.OK].includes(response.status)) {
        config?.successMsg && message.success(config.successMsg);
        if (config?.successHandler) {
          config.successHandler(response.data);
          return;
        }

        return response.data;
      }

      onHandleError(response);

      return;
    } catch (e) {
      if (config?.errorHandler) {
        config.errorHandler(e);
      }
      // @ts-ignore
      onHandleError(e?.response || e.message);
    }
  };

  const swrFetcher = (url: string) => get(url).then((res) => res);

  const swrInfiniteFetcher = async (url: string, config?: IHttpClientConfig) => {
    try {
      client.defaults.headers.common["X-Request-Id"] = uuidv4();
      const response = await (config?.bare ? axios : client).get(url, config);
      if (httpStatusCodes.OK === response.status) {
        const cursors = response.headers?.link ? parseLinkHeader(response.headers.link) : {};
        const count = response?.headers?.["x-result-count"];
        const lastUpdatedAt = response?.headers?.["x-updated-at-max"];
        const responseDate = response?.headers?.["date"];
        return { data: response.data, count, cursors, lastUpdatedAt, key: url, date: responseDate };
      }

      if (config?.errorHandler) {
        config.errorHandler(response);
      }
      return onHandleError(response);
    } catch (e) {
      if (config?.errorHandler) {
        config.errorHandler(e);
      }
      // @ts-ignore
      onHandleError(e?.response || e.message);
    }
  };

  const fetchAll = async (url: string, config?: IHttpClientConfig) => {
    let paginatedData: any = [];
    let metadata: any = {
      count: 0,
    };

    const doRequest = async (requestURL: string, config?: IHttpClientConfig) => {
      const response: any = await swrInfiniteFetcher(requestURL, config);
      const nextURL = response?.cursors?.next?.url;

      if (response?.data) {
        paginatedData.push(...response.data);
        metadata = {
          count: response?.count + metadata.count,
          cursors: response?.cursors,
          lastUpdatedAt: response?.lastUpdatedAt,
          key: response?.key,
        };
      }

      if (nextURL) {
        await doRequest(nextURL, config);
      }
    };

    await doRequest(url, config);

    if (config?.successHandler) {
      config.successHandler(paginatedData, metadata);
    }
  };

  const bareFetcher = async (url: string) => {
    try {
      client.defaults.headers.common["X-Request-Id"] = uuidv4();
      const response = await axios.get(url);
      return response.data;
    } catch (err) {
      // @ts-ignore
      return console.error(err.response);
    }
  };

  const getKey =
    (url: string | undefined, preset: "next" | "prev" = "next") =>
    (index: number, prev: object): string | undefined => {
      if (prev) return lodashGet(prev, `cursors.${preset}.url`, "");

      return url;
    };

  const multipleFetcher = (urls: string[]) => {
    const fetch = (u: string) =>
      axios
        .get(u)
        .then((r) => r.data)
        .catch((error) => error?.response);

    return Promise.all(urls.map(fetch));
  };

  return {
    client,
    basePath: baseURL,
    headers: defaultHeaders,
    apiVersion: API_VERSION,

    get,
    fetchAll,
    options,
    patch,
    post,
    chunkedPost,
    put,
    remove,
    swrFetcher,
    swrInfiniteFetcher,
    getKey,
    bareFetcher,
    multipleFetcher,
  };
};

export default createHTTPClient;
