import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { isEmpty } from "lodash";

import { Session } from "types";

import { getOrCreateSessionId, onGetHijackedSession, onGetSession } from "utils/authentication-utils";

// Sessions atoms
const ironSessionAtom = atom<Session | undefined>(undefined);
ironSessionAtom.debugLabel = "ironSession";

const userSessionAtom = atom<Session | undefined>(undefined);
userSessionAtom.debugLabel = "userSession";

const loadingSessionAtom = atom<boolean>(false);
loadingSessionAtom.debugLabel = "loadingSessionAtom";

const now = Date.now();
const initializeSessionEffectAtom = atomEffect((get, set) => {
  const ironSession = get(ironSessionAtom);
  const userSession: null | Session = onGetSession();
  const hijackedSession: null | Session = onGetHijackedSession();

  let session: null | Session = null;

  set(loadingSessionAtom, true);

  switch (true) {
    case !isEmpty(hijackedSession):
      if (hijackedSession) {
        session = hijackedSession;
      }
      break;
    case !isEmpty(ironSession):
      if (ironSession) {
        session = ironSession;
      }
      break;
    case !isEmpty(userSession):
      if (userSession) {
        session = userSession;
      }
      break;
  }

  set(loadingSessionAtom, false);

  if (!session) {
    if (!["/login/", "/password/forgot/", "/password/reset/"].includes(window.location.pathname)) {
      window.location.href = "/login";
    }
  } else {
    set(userSessionAtom, {
      ...session,
      sessionId: getOrCreateSessionId(),
      sessionTime: now,
      isHijacked: !isEmpty(hijackedSession),
    });
  }
});
initializeSessionEffectAtom.debugLabel = "initializeSessionEffect";
initializeSessionEffectAtom.debugPrivate = true;

export { initializeSessionEffectAtom, ironSessionAtom, loadingSessionAtom, userSessionAtom };
