import { Dayjs } from "dayjs";
import { atom } from "jotai";

import { mapActionReducerAtom } from "components/map/atoms/map-actions-reducer";

import { extractUuidFromURL } from "utils/uuid-utils";

export type VehicleViewType = "list" | "live" | "timeline" | "playback";
export type DriverViewType = "list" | "live" | "timeline";
export type AreaViewType = "list" | "new" | "edit";
export type CurrentTabType = "vehicle" | "driver" | "area";

export const TABS: { [key: string]: CurrentTabType } = {
  VEHICLE: "vehicle",
  DRIVER: "driver",
  AREA: "area",
};

const mapViewTabAtom = atom<CurrentTabType | undefined>("vehicle");
mapViewTabAtom.debugLabel = "mapViewTab";

// This represents either vehicleId or driversId
const objectIdAtom = atom<string | undefined>((get) => {
  const mapViewTab = get(mapViewTabAtom);
  const actions = get(mapActionReducerAtom);

  return actions && mapViewTab ? extractUuidFromURL(actions?.[mapViewTab]?.url) : undefined;
});
objectIdAtom.debugLabel = "objectId";

const initializingViewAtom = atom<boolean>(true);
initializingViewAtom.debugLabel = "initializingView";

const mapViewAtom = atom<VehicleViewType | DriverViewType | undefined>((get) => {
  if (get(initializingViewAtom)) {
    return undefined;
  }

  const state = get(mapActionReducerAtom);
  const tab = get(mapViewTabAtom);

  if (tab) {
    return state?.[tab as string]?.view;
  }

  return "list";
});
mapViewAtom.debugPrivate = true;

const mapTimelineDatesAtom = atom<[string, string] | null>((get) => {
  const state = get(mapActionReducerAtom);
  const tab = get(mapViewTabAtom);

  if (tab) {
    return state?.[tab as string]?.timelineDates?.map((date: Dayjs) => date.toString());
  }

  return null;
});
mapTimelineDatesAtom.debugPrivate = true;

export { mapViewTabAtom, mapViewAtom, mapTimelineDatesAtom, objectIdAtom, initializingViewAtom };
