import { t } from "@lingui/macro";
import { message } from "antd";
import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { first, isEqual } from "lodash";
import omit from "lodash/omit";

import { MapLayers } from "components/deck-gl/instance";
import { DEFAULT_VERTICAL_SPLIT_VIEW } from "components/map/hooks/layout";
import { useApplicationContext } from "contexts/app";

export const mapSettingsKey = "navirec-map-layer-settings-v1";

export type MapControlsMenu =
  | "map-layer"
  | "map-settings"
  | "data-layers"
  | "route-planner"
  | "send-message"
  | "search-vehicles"
  | undefined;

export interface MapSettings {
  mapLayer?: {
    provider: "";
    layer: "";
  };
  provider: MapLayers;
  showTrafficLayer: boolean;
  showAreas: boolean;
  showPoints: boolean;
  vehicleNames: boolean;
  smallerVehicleIcons: boolean;
  smallerPointsIcons: boolean;
  showStats?: boolean;
  groupVehicles: boolean;
  showFullDay: boolean;
  chartAndMapViewHeight?: number[];
  listAndMapViewWidth: number[];
  showVehicleDetails: boolean;
  showDriverDetails: boolean;
  showTimeInPlaybackRangePicker: boolean;
  mapControlMenu: MapControlsMenu;
  showRecentTrailingLine?: boolean;
  //playback
  showParkingTimes: boolean;
  colorTrips: boolean;
  showFuture?: boolean;
  zoomToCurrentTrip?: boolean;
  showEventsIcon?: boolean;
  showStopsIcon?: boolean;
  showTrailingLine?: boolean;
  iconAnimation?: boolean;
  showDirections?: boolean;
  showActivities?: boolean;
  playbackDuration: number;
  showStreamingStats?: boolean;
}

const mapSettingsDefault = {
  provider: "google-road" as MapLayers,
  showTrafficLayer: false,
  showAreas: true,
  showPoints: true,
  vehicleNames: true,
  smallerVehicleIcons: true,
  showStats: false,
  smallerPointsIcons: true,
  groupVehicles: true,
  showFullDay: false,
  showParkingTimes: true,
  colorTrips: true,
  chartAndMapViewHeight: [70, 30],
  listAndMapViewWidth: DEFAULT_VERTICAL_SPLIT_VIEW,
  showVehicleDetails: false,
  showDriverDetails: false,
  showTimeInPlaybackRangePicker: false,
  mapControlMenu: undefined,
  showFuture: false,
  zoomToCurrentTrip: true,
  showEventsIcon: true,
  showStopsIcon: true,
  showRecentTrailingLine: true,
  showTrailingLine: false,
  iconAnimation: true,
  showDirections: true,
  showActivities: true,
  playbackDuration: 5,
  showStreamingStats: false,
  showAddressSearchBackends: false,
};

const mapSettingsAtom = atomWithStorage<MapSettings>(mapSettingsKey, mapSettingsDefault, undefined, {
  getOnInit: true,
});
mapSettingsAtom.debugLabel = "mapSettings";

const ignoreMapClickActionAtom = atom<boolean>(false);
ignoreMapClickActionAtom.debugPrivate = true;

const ignoreMapViewStateChangeAtom = atom<boolean>(false); // preserves map view port even when values changes.
ignoreMapViewStateChangeAtom.debugPrivate = true;

export const getMapLayerProvider = (layers: string[] | undefined, provider: string) => {
  if (!layers?.includes(provider)) {
    return first(layers) as MapLayers;
  }

  return provider;
};

const useMapSettings = () => {
  const { configuration } = useApplicationContext();
  const [settings, setSettings] = useAtom<MapSettings>(mapSettingsAtom);

  const onChangeSettings = (key: string, value: string | boolean | number[] | number | MapControlsMenu) => {
    setSettings((res: MapSettings) => ({
      ...res,
      [key]: value,
    }));
  };

  const mapSettings = {
    ...mapSettingsDefault,
    ...settings,
    provider: getMapLayerProvider(configuration?.map_layers, settings.provider),
  };

  const onResetSettings = () => {
    setSettings({ ...mapSettingsDefault, mapControlMenu: "map-settings" });
    message.success(t`Default map settings has been applied.`);
  };

  const isSettingsUnchanged = isEqual(
    omit(mapSettings, ["mapControlMenu"]),
    omit(mapSettingsDefault, ["mapControlMenu"])
  );

  return { settings: mapSettings, onChangeSettings, onResetSettings, isSettingsUnchanged };
};

export { mapSettingsAtom, useMapSettings, ignoreMapClickActionAtom, ignoreMapViewStateChangeAtom };
