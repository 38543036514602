import { atom } from "jotai";
import { last } from "lodash";

import { mapViewAtom } from "components/map/atoms/map-actions";
import { mapSettingsAtom } from "components/map/atoms/settings";

const TIMELINE_CONTROL_HEIGHT = 50;
const CHART_HEIGHT = 300;
const MIN_SIDEBAR_WIDTH = 200;
const MAX_RIGHT_SIDEBAR_WIDTH = 350;
const DEFAULT_VERTICAL_SPLIT_VIEW = [20, 80];

const getChartHeightAtom = atom((get) => {
  const settings = get(mapSettingsAtom);
  const view = get(mapViewAtom);

  if (view === "list") {
    return 0;
  }

  if (view === "playback") {
    const height = Number(last(settings.chartAndMapViewHeight) || 0);
    if (height <= TIMELINE_CONTROL_HEIGHT) {
      return CHART_HEIGHT;
    }

    return last(settings.chartAndMapViewHeight) || CHART_HEIGHT;
  }

  return TIMELINE_CONTROL_HEIGHT;
});
getChartHeightAtom.debugPrivate = true;

export {
  TIMELINE_CONTROL_HEIGHT,
  CHART_HEIGHT,
  DEFAULT_VERTICAL_SPLIT_VIEW,
  MAX_RIGHT_SIDEBAR_WIDTH,
  MIN_SIDEBAR_WIDTH,
  getChartHeightAtom,
};
