import { Dayjs } from "dayjs";
import { atomWithReducer } from "jotai/utils";

import { AreaViewType, DriverViewType, VehicleViewType } from "components/map/atoms/map-actions";

import { Area } from "types";

export type MapActions =
  //vehicle actions
  | "SET_VEHICLE"
  | "SET_VEHICLES_VIEW"
  | "OPEN_VEHICLE_LIST_VIEW"
  | "OPEN_VEHICLE_LIVE_VIEW"
  | "OPEN_VEHICLE_TIMELINE_VIEW"
  | "SET_VEHICLES_PLAYBACK_TIME"
  // drivers actions
  | "SET_DRIVER"
  | "SET_DRIVERS_VIEW"
  | "SET_DRIVER_TIMELINE_DATES"
  | "OPEN_DRIVER_LIST_VIEW"
  | "OPEN_DRIVER_LIVE_VIEW"
  | "OPEN_DRIVER_TIMELINE_VIEW"
  // areas actions
  | "OPEN_AREA_EDITOR"
  | "OPEN_AREA_LIST"
  | "UPDATE_AREA_DATA"
  | "SET_FEATURE_ID"
  // reset vew
  | "RESET";

export interface MapViewActions {
  type: MapActions;
  vehicle?: {
    view?: VehicleViewType;
    url?: string;
    timelineDates?: [Dayjs, Dayjs];
    time?: Dayjs;
  };
  driver?: { view?: DriverViewType; vehicle?: string; url?: string; timelineDates?: [Dayjs, Dayjs] };
  area?: { view?: AreaViewType; url?: string; data?: Area; initial?: Area; featureId?: string };
}

export const MAP_ACTIONS: { [key: string]: MapActions } = {
  SET_VEHICLE: "SET_VEHICLE",
  SET_VEHICLES_VIEW: "SET_VEHICLES_VIEW",
  SET_VEHICLES_PLAYBACK_TIME: "SET_VEHICLES_PLAYBACK_TIME",
  OPEN_VEHICLE_LIST_VIEW: "OPEN_VEHICLE_LIST_VIEW",
  OPEN_VEHICLE_LIVE_VIEW: "OPEN_VEHICLE_LIVE_VIEW",
  OPEN_VEHICLE_TIMELINE_VIEW: "OPEN_VEHICLE_TIMELINE_VIEW",

  SET_DRIVER: "SET_DRIVER",
  SET_DRIVERS_VIEW: "SET_DRIVERS_VIEW",
  SET_DRIVER_TIMELINE_DATES: "SET_DRIVER_TIMELINE_DATES",
  OPEN_DRIVER_LIST_VIEW: "OPEN_DRIVER_LIST_VIEW",
  OPEN_DRIVER_LIVE_VIEW: "OPEN_DRIVER_LIVE_VIEW",
  OPEN_DRIVER_TIMELINE_VIEW: "OPEN_DRIVER_TIMELINE_VIEW",

  OPEN_AREA_EDITOR: "OPEN_AREA_EDITOR",
  OPEN_AREA_LIST: "OPEN_AREA_LIST",
  UPDATE_AREA_DATA: "UPDATE_AREA_DATA",
  SET_FEATURE_ID: "SET_FEATURE_ID",

  RESET: "RESET",
};

const defaultArea = {
  view: "list",
  url: null,
  data: null,
  featureId: null,
  initial: null,
};

const defaultDrivers = {
  url: null,
  timelineDates: [],
  view: "list",
};

const mapActionReducer = (state: any, action: MapViewActions) => {
  switch (action.type) {
    /* Vehicles actions implementation */
    case "SET_VEHICLE":
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          url: action.vehicle?.url,
        },
      };
    case "OPEN_VEHICLE_LIST_VIEW":
      return {
        ...state,
        vehicle: {
          url: null,
          timelineDates: [],
          view: "list",
        },
        area: defaultArea,
        driver: defaultDrivers,
      };
    case "OPEN_VEHICLE_LIVE_VIEW":
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          view: "live",
          url: action.vehicle?.url,
          timelineDates: [],
        },
      };
    case "OPEN_VEHICLE_TIMELINE_VIEW":
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          url: action.vehicle?.url,
          view: action.vehicle?.view,
          timelineDates: action.vehicle?.timelineDates,
          time: action.vehicle?.time,
        },
        driver: defaultDrivers,
      };
    case "SET_VEHICLES_VIEW":
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          view: action.vehicle?.view,
          time: action?.vehicle?.time,
        },
        driver: defaultDrivers,
      };
    case "SET_VEHICLES_PLAYBACK_TIME":
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          time: action?.vehicle?.time,
        },
      };
    /* Drivers actions implementation */
    case "SET_DRIVER":
      return {
        ...state,
        driver: {
          ...state.driver,
          url: action.driver?.url,
        },
      };
    case "OPEN_DRIVER_LIST_VIEW":
      return {
        ...state,
        driver: {
          url: null,
          timelineDates: [],
          view: "list",
        },
        area: defaultArea,
      };
    case "OPEN_DRIVER_LIVE_VIEW":
      return {
        ...state,
        driver: {
          ...state?.driver,
          view: "live",
          url: action.driver?.url,
          vehicle: action.driver?.vehicle,
          timelineDates: [],
        },
      };
    case "OPEN_DRIVER_TIMELINE_VIEW":
      return {
        ...state,
        driver: {
          ...state.driver,
          url: action.driver?.url,
          view: action.driver?.view,
          timelineDates: action.driver?.timelineDates,
        },
      };
    case "SET_DRIVERS_VIEW":
      return {
        ...state,
        driver: {
          ...state?.driver,
          view: action.driver?.view,
        },
      };
    case "SET_DRIVER_TIMELINE_DATES":
      return {
        ...state,
        driver: {
          ...state?.driver,
          timelineDates: action.driver?.timelineDates,
        },
      };

    /* Areas actions implementation */
    case "OPEN_AREA_EDITOR":
      return {
        ...state,
        vehicle: null,
        driver: null,
        area: {
          view: action.area?.view,
          url: action.area?.url,
          data: action.area?.data,
          featureId: state?.area?.featureId,
        },
      };
    case "OPEN_AREA_LIST":
      return {
        ...state,
        area: {
          view: "list",
          url: null,
          data: null,
          featureId: null,
          initial: null,
        },
      };
    case "SET_FEATURE_ID":
      return {
        ...state,
        area: {
          ...action.area,
          featureId: action.area?.featureId,
        },
      };

    case "UPDATE_AREA_DATA":
      const area = {
        ...state.area,
        data: action.area?.data,
      };

      if (action.area?.initial) {
        area["initial"] = action.area?.initial;
      }

      return {
        ...state,
        area: area,
      };
    case "RESET":
      return { ...initialState };
    default:
      console.error("unknown action");
  }
};

const initialState = {
  vehicle: {
    url: "",
    view: "list",
    timelineDates: [],
  },
  driver: {
    url: "",
    view: "list",
    timelineDates: [],
  },
  area: {
    url: "",
    view: "list",
  },
};

const mapActionReducerAtom = atomWithReducer(initialState, mapActionReducer);
mapActionReducerAtom.debugLabel = "mapActions";

export { mapActionReducerAtom };
