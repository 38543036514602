import { AxiosInstance } from "axios";
import * as rax from "retry-axios";

const retryRequest = (client: AxiosInstance) => {
  client.defaults.raxConfig = {
    instance: client,
    retry: 5,
    noResponseRetries: 5,
    retryDelay: 2000,
    httpMethodsToRetry: ["GET"],
    statusCodesToRetry: [[500, 599]],
    backoffType: "exponential",
    // onRetryAttempt: (err) => {
    //   // Modify headers for the retry request, not the instance defaults
    //   if (err.config && err.config.headers) {
    //     // omit(err.config.headers, ["Chaos-Ratio", "Chaos-Status-Codes"]);
    //     err.config.headers["Chaos-Ratio"] = "0"; // Add or modify the header
    //   }
    // },
  };

  rax.attach(client);
};

export default retryRequest;
